import {
  ActionIcon,
  Card,
  Center,
  Collapse,
  Divider,
  Grid,
  Group,
  Loader,
  RingProgress,
  Space,
  Stack,
  Text,
} from "@mantine/core";
import { IconSelector } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import parse from "html-react-parser";
import { News, User } from "./utils/interfaces";
import { useCookies } from "react-cookie";
import { useClearCookies } from "./utils/useIsAuthenticated";

function formatDate(date: Date | string) {
  if (!(date instanceof Date)) {
    date = new Date(date);
  }
  var year = date.getFullYear();
  var month = (date.getMonth() + 1).toString().padStart(2, "0");
  var day = date.getDate().toString().padStart(2, "0");

  return `${year}年 ${month}月 ${day}日`;
}

export default function Home() {
  //cookies management
  const [cookies] = useCookies();
  const clearCookies = useClearCookies();

  const [newsData, setNewsData] = useState<News[]>([]);
  const [newsDisplay, setNewsDisplay] = useState<boolean[]>([]);
  const [userData, setUserData] = useState<User | null>(null);
  const [timeData, setTimeData] = useState<{
    offer_time: string;
    start_time: string;
  } | null>(null);
  useEffect(() => {
    if (newsData.length)
      setNewsDisplay([true, ...Array(newsData.length - 1).fill(false)]);
  }, [newsData]);

  useEffect(() => {
    if (cookies.userID)
      fetch(
        `https://cp.amixcom.jp/api/mgmt_api/info.php?user_id=${cookies.userID}`,
        {
          method: "GET",
          headers: {
            "x-auth-token": cookies.token,
          },
        }
      )
        .then((response) => {
          if (response.status === 403) {
            clearCookies();
            throw new Error("Unauthorized");
          } else if (!response.ok) throw new Error("Failed to fetch data");
          else return response.json();
        })
        .then((data) => {
          setNewsData(data.info.info);
          setUserData(data.user.users[0]);
          setTimeData(data.time);
        })
        .catch((err) => {});
    // eslint-disable-next-line
  }, [cookies.userID, cookies.token]);

  const newsMap = newsData.map((news, index) => (
    <Card
      padding="lg"
      radius="sm"
      withBorder
      style={{
        minWidth: "39vw",
      }}
      key={news.message_id}
    >
      <Card.Section>
        <Group justify="space-between" m={10} mb={0}>
          <Text style={{ fontWeight: 700 }}>{news.subject}</Text>
          <ActionIcon
            variant="light"
            onClick={() => {
              setNewsDisplay((prevState) =>
                prevState.map((state, idx) => {
                  if (idx === index) return !state;
                  else return state;
                })
              );
            }}
          >
            <IconSelector />
          </ActionIcon>
        </Group>
      </Card.Section>
      <Collapse in={newsDisplay[index]}>
        <Divider my="xs" />
        {parse(news.message)}
      </Collapse>
    </Card>
  ));
  return (
    <Center m={20} p={15}>
      <Grid>
        <Grid.Col span={{ base: 12, md: 9 }}>
          <div style={{ marginBottom: 20 }}>
            <Text
              style={{ textAlign: "center", fontWeight: 600, fontSize: 50 }}
            >
              ami:tool
            </Text>
            <Text size="lg" style={{ textAlign: "center" }}>
              メールアドレスが５件までご利用いただけます。
            </Text>
            {/* <Text size="lg" style={{ textAlign: "center" }}>
              インターネットアプリケーションが１つになりました
            </Text> */}
          </div>
          <Center>
            <Card
              padding="sm"
              radius="sm"
              withBorder
              style={{
                minWidth: "40vw",
              }}
            >
              <Text style={{ textAlign: "center", fontWeight: 700 }} size="lg">
                お知らせ
              </Text>
              <Space h="md" />
              <Stack>{newsMap}</Stack>
            </Card>
          </Center>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 3 }}>
          <Card
            padding="sm"
            radius="sm"
            withBorder
            style={{
              minWidth: "10vw",
              minHeight: 200,
              justifyContent: " center",
            }}
          >
            <Text style={{ textAlign: "center", fontWeight: 500 }} size="lg">
              サービス申込日
            </Text>
            <Divider my="sm" />
            <Text style={{ textAlign: "center" }} size="md">
              {timeData ? formatDate(timeData.offer_time) : ""}
            </Text>
            <Divider my="sm" variant="dashed" />
            <Text style={{ textAlign: "center", fontWeight: 500 }} size="lg">
              契約開始日
            </Text>
            <Divider my="sm" />
            <Text style={{ textAlign: "center" }} size="md">
              {timeData ? formatDate(timeData.start_time) : ""}
            </Text>
          </Card>
          <Space h="md" />
          <Card
            padding="lg"
            radius="sm"
            withBorder
            style={{ minWidth: "10vw", minHeight: 300 }}
          >
            <Text style={{ textAlign: "center", fontWeight: 500 }} size="lg">
              容量
            </Text>
            <Divider my="sm" />
            {userData ? (
              <>
                <Center>
                  <RingProgress
                    sections={[
                      {
                        value: Math.round(
                          ((userData.used_disk_web + userData.used_disk_mail) /
                            userData.setting.disk.base_size) *
                            100
                        ),
                        color: "#c92a2a",
                      },
                    ]}
                    size={150}
                    label={
                      <Text c="#c92a2a" fw={700} ta="center" size="xl">
                        {Math.round(
                          ((userData.used_disk_web + userData.used_disk_mail) /
                            userData.setting.disk.base_size) *
                            100
                        )}
                        %
                      </Text>
                    }
                  />
                </Center>
                <div style={{ padding: "0 1vw" }}>
                  <Group justify="space-between">
                    <Text>空き容量</Text>
                    <Text>
                      {(
                        userData.setting.disk.base_size -
                        userData.used_disk_mail -
                        userData.used_disk_web
                      ).toLocaleString()}
                      MB
                    </Text>
                  </Group>
                  <Group justify="space-between">
                    <Text>利用容量</Text>
                    <Text>
                      {(
                        userData.used_disk_mail + userData.used_disk_web
                      ).toLocaleString()}
                      MB
                    </Text>
                  </Group>
                </div>
              </>
            ) : (
              <Loader size={50} />
            )}
          </Card>
        </Grid.Col>
      </Grid>
    </Center>
  );
}
