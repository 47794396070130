import {
  Alert,
  Button,
  Center,
  PasswordInput,
  Table,
  Text,
} from "@mantine/core";
import React, { useState } from "react";
import { IData } from "./utils/types";
import { useCookies } from "react-cookie";
import { notifications } from "@mantine/notifications";
import { useClearCookies } from "../utils/useIsAuthenticated";
import { IconInfoCircle } from "@tabler/icons-react";

interface Props {
  filterData: IData;
}

export default function ChangePassword({ filterData }: Props) {
  const [oldPass, setOldPass] = useState(filterData.password);
  const [newPass, setNewPass] = useState("");
  const [cookies] = useCookies();
  const clearCookies = useClearCookies();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    //check if new pass is same as old pass and the length is between 8 and 15
    if (newPass === oldPass || newPass.length < 8 || newPass.length > 15) {
      notifications.show({
        color: "red",
        title: "パスワードの変更",
        message: "入力されたパスワードは無効です。",
      });
      return;
    }
    //post data
    fetch(
      `https://cp.amixcom.jp/api/mgmt_api/email.php?user_id=${cookies.userID}`,
      {
        method: "PUT",
        headers: {
          "x-auth-token": cookies.token,
        },
        body: JSON.stringify({
          email_id: filterData.email_id,
          pop_id: filterData.email.split("@")[0],
          password: newPass,
          user_id: cookies.username,
        }),
      }
    ).then((response) => {
      if (response.ok) {
        setOldPass(newPass);
        notifications.show({
          color: "green",
          title: "パスワードの変更",
          message: "パスワードを変更しました。",
        });
        setNewPass("");
      } else if (response.status === 403) {
        clearCookies();
        throw new Error();
      } else
        notifications.show({
          color: "red",
          title: "パスワードの変更",
          message: "パスワードの変更に失敗しました。",
        });
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Table>
        <Table.Tbody>
          <Table.Tr h="50px">
            <Table.Td>
              <Text ta="center" fw={700}>
                メールアドレス
              </Text>
            </Table.Td>
            <Table.Td>{filterData.email}</Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Td>
              <Text ta="center" fw={700}>
                現在のパスワード
              </Text>
            </Table.Td>
            <Table.Td>
              <PasswordInput name="old_password" value={oldPass} readOnly />
            </Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Td>
              <Text ta="center" fw={700}>
                新パスワード
              </Text>
            </Table.Td>
            <Table.Td>
              <PasswordInput
                name="new_password"
                value={newPass}
                onChange={(event) => setNewPass(event.currentTarget.value)}
              />
            </Table.Td>
          </Table.Tr>
        </Table.Tbody>
      </Table>
      <Alert variant="light" title="注意点" icon={<IconInfoCircle />} mt={10}>
        パスワードは8文字以上、１５文字以内で設定してください。
        <br />
        パスワードには以下の文字が使用できます。
        <br />
        • 英字 A～Z、a～z
        <br />
        • 数字 0～9
        <br />
        • 記号 . - _<br />
        ※メールアドレス同じ文字列はセキュリティ上使用できません。
        安易に推測されないパスワードを設定してください。
        <br />
      </Alert>
      <Center>
        <Button type="submit">変更</Button>
      </Center>
    </form>
  );
}
