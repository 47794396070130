import {
  ActionIcon,
  Button,
  Card,
  Center,
  Divider,
  Flex,
  Grid,
  Group,
  Modal,
  Space,
  Table,
  Text,
  TextInput,
  ThemeIcon,
} from "@mantine/core";
import {
  IconEdit,
  IconInfoCircle,
  IconPlus,
  IconTrash,
} from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { MailServer, Email } from "./utils/interfaces";
import { generatePassword } from "./utils/generator";
import { useDisclosure } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { useCookies } from "react-cookie";
import { useClearCookies } from "./utils/useIsAuthenticated";
import MailFilter from "./MailFilter/MailFilter";

const emailRows = (emails: Email[]) => {
  return emails.map((email) => <div key={email.email_id}>{email.email}</div>);
};

interface modalContentType {
  type: string;
  email_id: number | null;
  email: string;
  title: string;
  content: JSX.Element;
}

export default function Mail() {
  //Modal handling for adding email (1st) and changing password (2nd)
  const [opened, { open, close }] = useDisclosure(false);
  //Modal handling for email filters
  const [openedFilter, { open: openFilter, close: closeFilter }] =
    useDisclosure(false);
  const [modalContent, setModalContent] = useState<modalContentType>({
    type: "",
    email_id: null,
    email: "",
    title: "",
    content: <></>,
  });
  const [emailIdFilter, setEmailIdFilter] = useState<number | null>(null);
  const [mailData, setMailData] = useState<MailServer | null>(null);
  const clearCookies = useClearCookies();

  //input
  const [newEmail, setNewEmail] = useState("");

  //cookies
  const [cookies] = useCookies();

  const fetchMails = () => {
    fetch(
      `https://cp.amixcom.jp/api/mgmt_api/email.php?mail_id=${cookies.mailID}&user_id=${cookies.userID}`,
      {
        method: "GET",
        headers: {
          "x-auth-token": cookies.token,
        },
      }
    )
      .then((response) => {
        if (response.ok) return response.json();
        else if (response.status === 403) {
          clearCookies();
          throw new Error();
        } else {
          return response.text();
        }
      })
      .then((data) => {
        setMailData(data.mail_servers[0]);
      });
  };

  // let domain;
  useEffect(() => {
    fetchMails();
    // eslint-disable-next-line
  }, []);

  const emailList = (emails: Email[]) => {
    return emails.map((email) => (
      <Group justify="space-between" m={8} key={email.email_id}>
        <Text>{email.email}</Text>
        <Flex gap={5}>
          <ActionIcon
            variant="subtle"
            onClick={() => {
              openFilter();
              setEmailIdFilter(email.email_id);
            }}
          >
            <IconEdit />
          </ActionIcon>
          <ActionIcon
            variant="light"
            onClick={() => {
              open();
              setModalContent({
                type: "delete",
                email_id: email.email_id,
                email: email.email,
                title: "メールアドレス削除",
                content: (
                  <Text>
                    「{email.email}」を削除しますか？
                    <br />
                    よろしければ「確認」ボタンを押してください。
                  </Text>
                ),
              });
            }}
          >
            <IconTrash />
          </ActionIcon>
        </Flex>
      </Group>
    ));
  };

  const modalConfirm = () => {
    if (modalContent.type === "add") {
      if (newEmail.length > 30 || newEmail.length < 1) {
        notifications.show({
          color: "red",
          title: "メールアドレスの追加",
          message: "メールアドレスは0文字以下で入力してください。",
        });
        return;
      }
      fetch(
        `https://cp.amixcom.jp/api/mgmt_api/email.php?user_id=${cookies.userID}`,
        {
          method: "POST",
          headers: {
            "x-auth-token": cookies.token,
          },
          body: JSON.stringify({
            popid: newEmail,
            username: cookies.username,
            mail_id: cookies.mailID,
            email: `${newEmail}@${cookies.username}.${cookies.domain}`,
            password: generatePassword(),
          }),
        }
      )
        .then((response) => {
          if (response.ok)
            notifications.show({
              color: "green",
              title: "メールアドレスの追加",
              message: "メールアドレスを追加しました。",
            });
          else if (response.status === 403) {
            clearCookies();
            throw new Error();
          } else {
            notifications.show({
              color: "red",
              title: "メールアドレスの追加",
              message: "メールアドレスの追加に失敗しました。",
            });
          }
        })
        .then(() => {
          fetchMails();
        });
      setNewEmail("");
    }
    //DELETE EMAIL
    else if (modalContent.type === "delete") {
      fetch(
        `https://cp.amixcom.jp/api/mgmt_api/email.php?user_id=${cookies.userID}`,
        {
          method: "DELETE",
          headers: {
            "x-auth-token": cookies.token,
          },
          body: JSON.stringify({
            email_id: modalContent.email_id,
            pop_id: modalContent.email.split("@")[0],
            user_id: cookies.username,
          }),
        }
      )
        .then((response) => {
          if (response.ok)
            notifications.show({
              color: "green",
              title: "メールアドレスの削除",
              message: "メールアドレスを削除しました。",
            });
          else if (response.status === 403) {
            clearCookies();
            throw new Error();
          } else
            notifications.show({
              color: "red",
              title: "メールアドレスの削除",
              message: "メールアドレスの削除に失敗しました。",
            });
        })
        .then(() => {
          fetchMails();
        });
    }
    close();
  };

  return (
    <>
      <MailFilter
        emailId={emailIdFilter}
        opened={openedFilter}
        close={closeFilter}
      />
      <Modal opened={opened} onClose={close} centered withCloseButton={false}>
        <Flex gap={10}>
          <ThemeIcon radius="xl">
            <IconInfoCircle style={{ width: "70%", height: "70%" }} />
          </ThemeIcon>
          <Text fw={700} size="lg">
            {modalContent.title}
          </Text>
        </Flex>
        <Center m={20}>{modalContent.content}</Center>
        <Divider my="sm" variant="dotted" />
        <Flex justify="flex-end" gap={10}>
          <Button onClick={modalConfirm}>確認</Button>
          <Button variant="outline" onClick={close}>
            キャンセル
          </Button>
        </Flex>
      </Modal>
      <Text
        style={{ textAlign: "center", fontWeight: 600, fontSize: 50 }}
        mt={10}
      >
        メールの管理
      </Text>
      <Center m={30}>
        <Grid>
          <Grid.Col span={{ base: 12, md: 6 }}>
            <Card
              padding="sm"
              withBorder
              style={{
                minWidth: "15vw",
              }}
            >
              <Card.Section
                style={{
                  textAlign: "center",
                  background: "rgb(201,42,42)",
                  color: "white",
                  fontWeight: 700,
                  padding: 5,
                }}
              >
                基本情報
              </Card.Section>
              <Table>
                <Table.Tbody>
                  <Table.Tr>
                    <Table.Td>登録済みメールアドレス</Table.Td>
                    <Table.Td>
                      {mailData ? emailRows(mailData.emails) : ""}
                    </Table.Td>
                  </Table.Tr>
                  <Table.Tr>
                    <Table.Td>POPサーバ名</Table.Td>
                    <Table.Td>
                      pop.{cookies.username}.{cookies.domain}
                    </Table.Td>
                  </Table.Tr>
                  <Table.Tr>
                    <Table.Td>SMTPサーバ名</Table.Td>
                    <Table.Td>
                      smtp.{cookies.username}.{cookies.domain}
                    </Table.Td>
                  </Table.Tr>
                  <Table.Tr>
                    <Table.Td>アカウント名</Table.Td>
                    <Table.Td>メールアドレスすべて</Table.Td>
                  </Table.Tr>
                  <Table.Tr>
                    <Table.Td>メールパスワード</Table.Td>
                    <Table.Td>各アドレスのパスワード</Table.Td>
                  </Table.Tr>
                </Table.Tbody>
              </Table>
            </Card>
            <Space h="sm" />
            {/* <Card
              padding="sm"
              withBorder
              style={{
                minWidth: "15vw",
              }}
            >
              <Card.Section
                style={{
                  textAlign: "center",
                  background: "rgb(201,42,42)",
                  color: "white",
                  fontWeight: 700,
                  padding: 5,
                }}
              >
                メールパスワード変更
              </Card.Section>
              <Table>
                <Table.Tbody>
                  <Table.Tr>
                    <Table.Td>メールアドレス</Table.Td>
                    <Table.Td>
                      {mailData && (
                        <Select
                          data={mailData.emails.map((mail) => mail.email)}
                          value={selectedEmail}
                          onChange={(val) => val && setSelectedEmail(val)}
                          allowDeselect={false}
                        />
                      )}
                    </Table.Td>
                  </Table.Tr>
                  <Table.Tr>
                    <Table.Td>新パスワード</Table.Td>
                    <Table.Td>
                      <PasswordInput
                        value={newPass}
                        onChange={(event) =>
                          setNewPass(event.currentTarget.value)
                        }
                      />
                    </Table.Td>
                  </Table.Tr>
                </Table.Tbody>
              </Table>
              <Center>
                <Button
                  onClick={() => {
                    if (newPass && selectedEmail) {
                      open();
                      setModalContent({
                        type: "change",
                        email_id: mailData
                          ? mailData.emails.find(
                              (email) => email.email === selectedEmail
                            )!.email_id
                          : null,
                        email: selectedEmail,
                        title: "メールパスワード変更",
                        content: (
                          <Text>
                            「{selectedEmail}@{cookies.username}.
                            {cookies.domain}
                            」のパスワードを変更しますか？
                            <br />
                            よろしければ「確認」ボタンを押してください。
                          </Text>
                        ),
                      });
                    }
                  }}
                >
                  変更
                </Button>
              </Center>
            </Card> */}
          </Grid.Col>

          {/* Delete or add new emails */}
          <Grid.Col span={{ base: 12, md: 6 }}>
            <Card
              padding="sm"
              withBorder
              style={{
                minWidth: "15vw",
              }}
            >
              <Card.Section
                style={{
                  textAlign: "center",
                  background: "rgb(201,42,42)",
                  color: "white",
                  fontWeight: 700,
                  padding: 5,
                }}
              >
                メールアドレス追加・削除
              </Card.Section>
              <Text size="md" style={{ textAlign: "left", margin: 10 }}>
                メールアドレスは５つまで作成可能です。
                <br />
                新規作成の場合は、メールアドレスを入力して「作成」ボタン、削除する場合は「削除」ボタンを押してください。
              </Text>
              <Divider my="xs" />
              {mailData && emailList(mailData?.emails)}
              {mailData && mailData?.emails.length < 5 ? (
                <Group justify="space-between" m={8}>
                  <Group gap="xs">
                    <TextInput
                      style={{ maxWidth: "15vw" }}
                      value={newEmail}
                      onChange={(event) =>
                        setNewEmail(event.currentTarget.value)
                      }
                    />
                    @{cookies.username}.{cookies.domain}
                  </Group>
                  <ActionIcon
                    variant="light"
                    color="green"
                    onClick={() => {
                      if (newEmail) {
                        open();
                        setModalContent({
                          type: "add",
                          email_id: null,
                          email: "",
                          title: "メールアドレス追加",
                          content: (
                            <Text>
                              「{newEmail}@{cookies.username}.{cookies.domain}
                              」を追加しますか？
                              <br />
                              よろしければ「確認」ボタンを押してください。
                            </Text>
                          ),
                        });
                      }
                    }}
                  >
                    <IconPlus />
                  </ActionIcon>
                </Group>
              ) : (
                <></>
              )}
            </Card>
          </Grid.Col>
        </Grid>
      </Center>
    </>
  );
}
