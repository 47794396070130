import {
  Blockquote,
  Container,
  Divider,
  Paper,
  Space,
  Stack,
  Text,
} from "@mantine/core";
import React from "react";

interface Props {
  URL: string[];
  emailName: string;
  password: string;
}

export default function SecondStep({ URL, emailName, password }: Props) {
  return (
    <Container size={1000}>
      <Paper withBorder shadow="md" p={30} mt={10} radius="md">
        <Blockquote h={10} pt={10} style={{ textAlign: "center" }}>
          お客さま情報の確認
        </Blockquote>
        <Space h="md" />
        <Divider my="md" variant="dashed" />
        <Stack>
          <div>
            <Text size="xs" c="dimmed">
              サブドメイン名
            </Text>
            <Text>
              {URL[0]}.{URL[1]}
            </Text>
          </div>
          <div>
            <Text size="xs" c="dimmed">
              パスワード
            </Text>
            <Text>{"*".repeat(password.length)}</Text>
          </div>
        </Stack>
        <Divider my="md" variant="dashed" />
      </Paper>
    </Container>
  );
}
