import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

const useIsAuthenticated = () => {
  const [cookies] = useCookies(["token", "userID", "username"]); // Specify cookies to use

  const token = cookies.token;
  const userID = cookies.userID;
  const username = cookies.username;
  return token && userID && username;
};

const useClearCookies = () => {
  // eslint-disable-next-line
  const [cookies, setCookies, removeCookies] = useCookies();
  const navigate = useNavigate();

  const clearCookies = () => {
    //remove cookies
    removeCookies("userID");
    removeCookies("username");
    removeCookies("domain");
    removeCookies("mailID");
    removeCookies("token");
    navigate("/login");
  };
  return clearCookies;
};

export { useIsAuthenticated, useClearCookies };
