import { useState } from "react";
import { useCookies } from "react-cookie";
import { useClearCookies } from "../../utils/useIsAuthenticated";

const useFilterFetch = () => {
  //cookies
  const [cookies] = useCookies();
  const clearCookies = useClearCookies();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const url = `https://cp.amixcom.jp/api/mgmt_api/email_filter.php?user_id=${cookies.userID}`;

  const fetchData = async (data: string) => {
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "x-auth-token": cookies.token,
        },
        body: data,
      });
      if (response.status === 403) {
        clearCookies();
        throw new Error();
      } else if (response.status === 500) {
        const errorMessage = await response.text();
        console.error(errorMessage);
        throw new Error();
      } else {
        // const result = await response.text();
        setSuccess(true);
        setError(false);
      }
    } catch (err) {
      setError(true);
      setSuccess(false);
    }
  };

  return { success, setSuccess, error, setError, fetchData };
};

export default useFilterFetch;
