import { Button, Center, Input, Space, Text, Textarea } from "@mantine/core";
import React, { useReducer } from "react";
import { IData } from "../utils/types";
import { SuccessAlert, ErrorAlert } from "./ErrorSuccessAlert";
import useFilterFetch from "../utils/useFilterFetch";

const initialState = {
  subject: "",
  body: "",
};

function reducer(
  state: typeof initialState,
  action: { type: string; payload?: any }
) {
  switch (action.type) {
    case "SET_SUBJECT":
      return { ...state, subject: action.payload };
    case "SET_BODY":
      return { ...state, body: action.payload };
    default:
      return state;
  }
}

export default function AutoReplyMails({
  filterData,
  fetchMailFilters,
}: {
  filterData: IData;
  fetchMailFilters: () => void;
}) {
  const [state, dispatch] = useReducer(reducer, {
    ...(filterData.filters && filterData.filters.vacation
      ? filterData.filters.vacation
      : initialState),
  });
  //Custom use fetch
  const { success, setSuccess, error, setError, fetchData } = useFilterFetch();

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    //set error if only one of the field is entered
    if ((state.subject && !state.body) || (!state.subject && state.body)) {
      setSuccess(false);
      setError(true);
      return;
    }

    const data =
      state.subject && state.body
        ? {
            subject: state.subject,
            body: state.body,
          }
        : undefined;

    const bodyData = JSON.stringify({
      email: {
        ...filterData,
        filter_revision: 0,
        filters: {
          ...filterData.filters,
          vacation: data,
        },
      },
    });
    fetchData(bodyData).then(() => fetchMailFilters());
  };
  return (
    <form onSubmit={handleSubmit}>
      <Text ml={25} mt={10} fw={700}>
        件名
      </Text>
      <Input
        name="subject"
        placeholder="（例）自動返信"
        value={state.subject}
        mx={20}
        onChange={(e) =>
          dispatch({
            type: "SET_SUBJECT",
            payload: e.currentTarget.value,
          })
        }
      />
      <Space h="md" />
      <Text ml={25} fw={700}>
        本文
      </Text>
      <Textarea
        name="body"
        placeholder="（例）20xx年xx日までお休みを頂いいております"
        value={state.body}
        mx={20}
        rows={5}
        onChange={(e) =>
          dispatch({
            type: "SET_BODY",
            payload: e.currentTarget.value,
          })
        }
      />
      <Center mt={20}>
        <Button justify="center" type="submit">
          自動返信の設定更新
        </Button>
      </Center>
      {success && <SuccessAlert />}
      {error && <ErrorAlert />}
    </form>
  );
}
