import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { MantineProvider, createTheme } from "@mantine/core";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Home from "./Home";
import App from "./App";
import Mail from "./Mail";
// import Web from "./Web";
import Inquiry from "./Inquiry";
import Account from "./Account/Account";
import Login from "./Login/Login";
import Logout from "./Logout";
import "@mantine/notifications/styles.css";
import { Notifications } from "@mantine/notifications";
// import PasswordReset from "./PasswordReset/PasswordReset";
import NewPassword from "./PasswordReset/NewPassword";
// import Register from "./Register/Register";
import NotFoundTitle from "./NotFound/NotFoundTitle";
import { CookiesProvider } from "react-cookie";
import Register from "./Register/Register";
import Kaiyaku from "./Account/Kaiyaku";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const theme = createTheme({
  primaryColor: "red",
  primaryShade: 9,
});

const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <App children={<Home />} />,
      errorElement: <NotFoundTitle />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/register",
      element: <Register />,
    },
    // {
    //   path: "/resetpassword",
    //   element: <PasswordReset />,
    // },
    {
      path: "/newpassword",
      element: <NewPassword />,
    },
    {
      path: "/mail",
      element: <App children={<Mail />} />,
    },
    // {
    //   path: "/web",
    //   element: <App children={<Web />} />,
    // },
    {
      path: "/inquire",
      element: <App children={<Inquiry />} />,
    },
    {
      path: "/account",
      element: <App children={<Account />} />,
    },
    {
      path: "/account/kaiyaku",
      element: <App children={<Kaiyaku />} />,
    },
    {
      path: "/logout",
      element: <App children={<Logout />} />,
    },
  ],
  {
    basename: "/tool",
  }
);

root.render(
  <CookiesProvider defaultSetOptions={{ path: "/" }}>
    <MantineProvider theme={theme}>
      <Notifications />
      <RouterProvider router={router} />
    </MantineProvider>
  </CookiesProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
