import {
  Alert,
  Blockquote,
  Container,
  Divider,
  Flex,
  Paper,
  PasswordInput,
  Select,
  Text,
  TextInput,
} from "@mantine/core";
import React from "react";
import { IconInfoCircle } from "@tabler/icons-react";

interface Props {
  URLState: [string[], React.Dispatch<React.SetStateAction<string[]>>];
  passwordState: [string, React.Dispatch<React.SetStateAction<string>>];
}

export default function FirstStep({ URLState, passwordState }: Props) {
  const [URL, setURL] = URLState;
  const [password, setPassword] = passwordState;
  return (
    <Container size={800}>
      <Paper withBorder shadow="md" p={30} mt={10} radius="md">
        <Blockquote py={5} style={{ fontSize: 24, textAlign: "center" }}>
          まずは、ご希望のサブドメインを入力
        </Blockquote>
        <Flex gap="xs" justify="center" mt={10} align="center">
          <Text>@</Text>
          <TextInput
            required
            value={URL[0]}
            onChange={(e) =>
              setURL((prevState) => [e.target.value, prevState[1]])
            }
          />
          <Text>.</Text>
          <Select
            data={["enat.jp"]}
            value={URL[1]}
            onChange={(e) => e && setURL((prevState) => [prevState[0], e])}
            allowDeselect={false}
          />
        </Flex>
        <Alert variant="light" title="注意点" icon={<IconInfoCircle />} mt={10}>
          • 日本語は利用できません。
          <br />
          • 半角英数字の中で[0～9 a～z -] が利用できます。
          <br />
          • 一文字目は [a～z] である必要があります。
          <br />
          • 2文字以上、8文字以内で入力してください。
          <br />
        </Alert>
        <Divider my="md" />
        <Blockquote py={5} style={{ fontSize: 24, textAlign: "center" }}>
          希望のパスワード
        </Blockquote>
        <Flex gap="xs" mt={10} align="center" justify="center" w={"100%"}>
          <PasswordInput
            required
            maw={"15vw"}
            style={{ flex: 2 }}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Flex>
        <Alert variant="light" title="注意点" icon={<IconInfoCircle />} mt={10}>
          パスワードは8文字以上、１５文字以内で設定してください。
          <br />
          パスワードには以下の文字が使用できます。
          <br />
          • 英字 A～Z、a～z
          <br />
          • 数字 0～9
          <br />
          • 記号 . - _<br />
          ※メールアドレス同じ文字列はセキュリティ上使用できません。
          安易に推測されないパスワードを設定してください。
          <br />
        </Alert>
      </Paper>
    </Container>
  );
}
