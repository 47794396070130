import {
  ActionIcon,
  Button,
  Center,
  Flex,
  Grid,
  Input,
  NativeSelect,
  Text,
} from "@mantine/core";
import { IconMinus, IconPlus } from "@tabler/icons-react";
import React, { useReducer } from "react";
import { IData } from "../utils/types";
import { SuccessAlert, ErrorAlert } from "./ErrorSuccessAlert";
import useFilterFetch from "../utils/useFilterFetch";

const initialState = {
  status: "01",
  email: "",
  terms: [
    {
      target: "01",
      value: "",
    },
  ],
};

function reducer(
  state: typeof initialState,
  action: { type: string; payload?: any }
) {
  switch (action.type) {
    case "SET_NOTIFY":
      return { ...state, status: action.payload };
    case "SET_NOTIFY_ADDRESS":
      return { ...state, email: action.payload };
    case "ADD_CONDITION_LIST":
      return {
        ...state,
        terms: [
          ...state.terms,
          {
            target: "01",
            value: "",
          },
        ],
      };
    case "REMOVE_CONDITION_LIST":
      return {
        ...state,
        terms: state.terms.filter((list, index) => index !== action.payload),
      };
    case "SET_CONDITION_LIST_ITEM_1":
      const newConditionList = [...state.terms];
      newConditionList[action.payload.index] = {
        ...newConditionList[action.payload.index],
        target: action.payload.value,
      };
      return { ...state, terms: newConditionList };
    case "SET_CONDITION_LIST_ITEM_2":
      const newConditionList2 = [...state.terms];
      newConditionList2[action.payload.index] = {
        ...newConditionList2[action.payload.index],
        value: action.payload.value,
      };
      return { ...state, terms: newConditionList2 };
    default:
      return state;
  }
}

export default function NotifyMails({
  filterData,
  fetchMailFilters,
}: {
  filterData: IData;
  fetchMailFilters: () => void;
}) {
  //Custom use fetch
  const { success, setSuccess, error, setError, fetchData } = useFilterFetch();
  const [state, dispatch] = useReducer(reducer, {
    ...(filterData.filters && filterData.filters.notice
      ? filterData.filters.notice
      : initialState),
  });
  const terms_append =
    state.terms && state.terms.length
      ? state.terms
      : [
          {
            target: "01",
            value: "",
          },
        ];

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const data = {
      status: state.status,
      email: state.email,
      terms:
        state.status === "03"
          ? state.terms.filter((term) => {
              return term.value ? true : false;
            })
          : undefined,
    };

    if (data.email) {
      fetchData(
        JSON.stringify({
          email: {
            ...filterData,
            filter_revision: 0,
            filters: {
              ...filterData.filters,
              notice: data,
            },
          },
        })
      ).then(() => fetchMailFilters());
    } else {
      setSuccess(false);
      setError(true);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid mt={15} mb={15}>
        <Grid.Col span={4}>
          <Text ta="center" mt={5} fw={700}>
            通知
          </Text>
        </Grid.Col>
        <Grid.Col span={8}>
          <NativeSelect
            value={state.status}
            name="status"
            data={[
              {
                label: "通知なし",
                value: "01",
              },
              {
                label: "全てのメールを通知する",
                value: "02",
              },
              {
                label: "条件に合致したもののみ通知する",
                value: "03",
              },
            ]}
            onChange={(e) =>
              dispatch({
                type: "SET_NOTIFY",
                payload: e.currentTarget.value,
              })
            }
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <Text ta="center" mt={5} fw={700}>
            通知先メールアドレス
          </Text>
        </Grid.Col>
        <Grid.Col span={8}>
          <Input
            value={state.email}
            name="email"
            placeholder="notify@example.com"
            onChange={(e) =>
              dispatch({
                type: "SET_NOTIFY_ADDRESS",
                payload: e.currentTarget.value,
              })
            }
          />
        </Grid.Col>
        {state.status === "03" && (
          <>
            <Grid.Col span={4}>
              <Text ta="center" mt={5} fw={700}>
                条件
              </Text>
            </Grid.Col>
            <Grid.Col span={8}>
              <Flex direction="column" gap={10}>
                {terms_append.map((item, index) => (
                  <Flex key={index} align="center" gap={10}>
                    <NativeSelect
                      name={`target[${index}]`}
                      value={item.target}
                      data={[
                        {
                          label: "件名",
                          value: "01",
                        },
                        {
                          label: "差出人",
                          value: "02",
                        },
                        {
                          label: "本文",
                          value: "03",
                        },
                      ]}
                      onChange={(e) =>
                        dispatch({
                          type: "SET_CONDITION_LIST_ITEM_1",
                          payload: { index, value: e.currentTarget.value },
                        })
                      }
                    />
                    に
                    <Input
                      name={`value[${index}]`}
                      value={item.value}
                      onChange={(e) =>
                        dispatch({
                          type: "SET_CONDITION_LIST_ITEM_2",
                          payload: { index, value: e.currentTarget.value },
                        })
                      }
                    />
                    を含む時
                    {index < state.terms.length - 1 ? (
                      <ActionIcon
                        radius="lg"
                        onClick={() =>
                          dispatch({
                            type: "REMOVE_CONDITION_LIST",
                            payload: index,
                          })
                        }
                      >
                        <IconMinus />
                      </ActionIcon>
                    ) : (
                      <ActionIcon
                        radius="lg"
                        onClick={() => dispatch({ type: "ADD_CONDITION_LIST" })}
                      >
                        <IconPlus />
                      </ActionIcon>
                    )}
                  </Flex>
                ))}
              </Flex>
            </Grid.Col>
          </>
        )}
      </Grid>
      <Center>
        <Button justify="center" type="submit">
          通知の設定更新
        </Button>
      </Center>
      {success && <SuccessAlert />}
      {error && <ErrorAlert />}
    </form>
  );
}
