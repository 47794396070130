import {
  ActionIcon,
  Button,
  Center,
  Flex,
  Grid,
  Input,
  NativeSelect,
  Text,
} from "@mantine/core";
import { IconMinus, IconPlus } from "@tabler/icons-react";
import React, { useReducer } from "react";
import { IData } from "../utils/types";
import { SuccessAlert, ErrorAlert } from "./ErrorSuccessAlert";
import useFilterFetch from "../utils/useFilterFetch";

const initialState = {
  status: "01",
  terms: [{ target: "01", value: "" }],
};

function reducer(
  state: typeof initialState,
  action: { type: string; payload?: any }
) {
  switch (action.type) {
    case "SET_CONFORM_REJECT":
      return { ...state, status: action.payload };
    case "ADD_JUDGE_LIST":
      return {
        ...state,
        terms: [...state.terms, { target: "01", value: "" }],
      };
    case "REMOVE_JUDGE_LIST":
      return {
        ...state,
        terms: state.terms.filter((list, index) => index !== action.payload),
      };
    case "SET_JUDGE_LIST_ITEM_1":
      const newjudgeList = [...state.terms];
      newjudgeList[action.payload.index] = {
        ...newjudgeList[action.payload.index],
        target: action.payload.value,
      };
      return { ...state, terms: newjudgeList };
    case "SET_JUDGE_LIST_ITEM_2":
      const newjudgeList2 = [...state.terms];
      newjudgeList2[action.payload.index] = {
        ...newjudgeList2[action.payload.index],
        value: action.payload.value,
      };
      return { ...state, terms: newjudgeList2 };
    default:
      return state;
  }
}

export default function RejectMails({
  filterData,
  fetchMailFilters,
}: {
  filterData: IData;
  fetchMailFilters: () => void;
}) {
  const [state, dispatch] = useReducer(reducer, {
    ...(filterData.filters && filterData.filters.refuse
      ? filterData.filters.refuse
      : initialState),
  });

  //Custom use fetch
  const { success, setSuccess, error, setError, fetchData } = useFilterFetch();

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    // 01:いずれかに当てはまるものを拒否 02:すべてに当てはまらないものを拒否
    const data = {
      status: state.status,
      terms: state.terms.filter((term) => {
        return term.value ? true : false;
      }),
    };
    if (data.terms.length) {
      fetchData(
        JSON.stringify({
          email: {
            ...filterData,
            filter_revision: 0,
            filters: {
              ...filterData.filters,
              refuse: data,
            },
          },
        })
      ).then(() => fetchMailFilters());
    } else {
      setSuccess(false);
      setError(true);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid mt={15} mb={15}>
        <Grid.Col span={4}>
          <Text ta="center" fw={700} mt={5}>
            条件合致時の動作
          </Text>
        </Grid.Col>
        <Grid.Col span={8}>
          <NativeSelect
            name="status"
            value={state.status}
            data={[
              {
                label: "下記に当てはまるメールを受信拒否する",
                value: "01",
              },
              {
                label: "下記以外のメールを受信破棄する",
                value: "02",
              },
            ]}
            onChange={(e) =>
              dispatch({
                type: "SET_CONFORM_REJECT",
                payload: e.currentTarget.value,
              })
            }
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <Text ta="center" fw={700} mt={5}>
            判定条件
          </Text>
        </Grid.Col>
        <Grid.Col span={8}>
          <Flex direction="column" gap={10}>
            {state.terms.map((item, index) => (
              <Flex key={index} align="center" gap={10}>
                <NativeSelect
                  name={`target[${index}]`}
                  value={item.target}
                  data={[
                    {
                      label: "件名",
                      value: "01",
                    },
                    {
                      label: "差出人",
                      value: "02",
                    },
                    {
                      label: "X-MAILER",
                      value: "03",
                    },
                    {
                      label: "宛先",
                      value: "04",
                    },
                    {
                      label: "CC",
                      value: "05",
                    },
                  ]}
                  onChange={(e) =>
                    dispatch({
                      type: "SET_JUDGE_LIST_ITEM_1",
                      payload: { index, value: e.currentTarget.value },
                    })
                  }
                />
                に
                <Input
                  name={`value[${index}]`}
                  value={item.value}
                  placeholder="[MEIWAKU]"
                  onChange={(e) =>
                    dispatch({
                      type: "SET_JUDGE_LIST_ITEM_2",
                      payload: { index, value: e.currentTarget.value },
                    })
                  }
                />
                を含む時
                {index < state.terms.length - 1 ? (
                  <ActionIcon
                    radius="lg"
                    onClick={() =>
                      dispatch({
                        type: "REMOVE_JUDGE_LIST",
                        payload: index,
                      })
                    }
                  >
                    <IconMinus />
                  </ActionIcon>
                ) : (
                  <ActionIcon
                    radius="lg"
                    onClick={() => dispatch({ type: "ADD_JUDGE_LIST" })}
                  >
                    <IconPlus />
                  </ActionIcon>
                )}
              </Flex>
            ))}
          </Flex>
        </Grid.Col>
      </Grid>
      <Center>
        <Button justify="center" type="submit">
          受信拒否の設定更新
        </Button>
      </Center>
      {success && <SuccessAlert />}
      {error && <ErrorAlert />}
    </form>
  );
}
