import "@mantine/core/styles.css";
import HeaderMenu from "./Header/HeaderMenu";
import FooterMenu from "./Footer/FooterMenu";
import { ReactNode, useEffect } from "react";
import { useIsAuthenticated } from "./utils/useIsAuthenticated";
import { useNavigate } from "react-router-dom";

function App({ children }: { children: ReactNode }) {
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
      return;
    }
  }, [navigate, isAuthenticated]);

  return (
    <div style={{ width: "100%" }}>
      <HeaderMenu />
      {children}
      <FooterMenu />
    </div>
  );
}

export default App;
