import {
  TextInput,
  PasswordInput,
  Paper,
  Title,
  Text,
  Container,
  // Group,
  Button,
  Flex,
  Alert,
  Select,
} from "@mantine/core";
import classes from "./Login.module.css";
import { IconInfoCircle } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { useIsAuthenticated } from "../utils/useIsAuthenticated";
import { SyntheticEvent, useEffect, useState } from "react";
import { useCookies } from "react-cookie";

export default function Login() {
  //define cookies
  // eslint-disable-next-line
  const [cookies, setCookies] = useCookies();
  const isAuthenticated = useIsAuthenticated();

  const [user, setUser] = useState("");
  const [domain, setDomain] = useState("enat.jp");
  const [password, setPassword] = useState("");
  const [errorState, setErrorState] = useState({
    user: false,
    password: false,
    incorrect: false,
  });

  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
      return;
    }
  }, [navigate, isAuthenticated]);

  // const redirectRegister = (
  //   e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  // ) => {
  //   e.preventDefault();
  //   navigate("/register");
  // };

  // const redirectForgotPassword = (
  //   e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  // ) => {
  //   e.preventDefault();
  //   navigate("/resetpassword");
  // };

  const authUser = (e: SyntheticEvent) => {
    e.preventDefault();
    if (!user)
      setErrorState((prevVal) => ({
        ...prevVal,
        user: true,
      }));
    if (!password)
      setErrorState((prevVal) => ({
        ...prevVal,
        password: true,
      }));
    if (user && password) {
      fetch("https://cp.amixcom.jp/api/mgmt_api/login.php", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          user,
          domain,
          password,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          setErrorState({
            user: false,
            password: false,
            incorrect: false,
          });
          //Set Cookies
          setCookies("userID", data.userID);
          setCookies("username", data.username);
          setCookies("domain", data.domain);
          setCookies("mailID", data.mailID);
          setCookies("token", data.token);
          setCookies("amix_account", data.amix_account);
          setCookies("payment_method", data.payment_method);
          navigate("/");
        })
        .catch(() => {
          setErrorState({
            user: false,
            password: false,
            incorrect: true,
          });
        });
    }
  };

  return (
    <Container size={500} mt={80}>
      <Title ta="center" className={classes.title} c={"#C92A2A"}>
        ami:tool
      </Title>
      <Paper withBorder shadow="md" p={30} mt={20} radius="md">
        <form onSubmit={authUser}>
          <Text fw={600} size="sm" mb={1}>
            ユーザー名
          </Text>
          <Flex gap="xs">
            <TextInput
              name="username"
              required
              value={user}
              onChange={(event) => setUser(event.currentTarget.value)}
              error={errorState.user}
            />
            <Text>.</Text>
            <Select
              name="domain"
              data={["enat.jp"]}
              defaultValue={"enat.jp"}
              onChange={(val) => (val ? setDomain(val) : {})}
              allowDeselect={false}
            />
          </Flex>
          <PasswordInput
            name="password"
            label="パスワード"
            mt="md"
            value={password}
            onChange={(event) => setPassword(event.currentTarget.value)}
            error={errorState.password}
          />
          {errorState.incorrect ? (
            <Alert variant="light" title="エラー" mt={20}>
              ユーザー名またはパスワードが間違っています
            </Alert>
          ) : (
            <></>
          )}
          <Alert variant="light" title="注意" icon={<IconInfoCircle />} mt={20}>
            ご利用のメールアドレスが「（例）mail@ami.enat.jp」の場合、
            「ユーザ名」は「ami」となります。
          </Alert>
          {/* <Group justify="right" mt="lg">
            <Checkbox label="次回から自動ログイン" />
            <Flex>
              <Text size="xs">
                パスワードを忘れた場合は
                <Anchor
                  component="button"
                  type="button"
                  size="xs"
                  onClick={(e) => redirectForgotPassword(e)}
                >
                  こちら
                </Anchor>
                から
              </Text>
            </Flex>
          </Group> */}
          <Button fullWidth mt="md" type="submit">
            ログイン
          </Button>
        </form>
      </Paper>
    </Container>
  );
}
