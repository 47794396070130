import {
  ActionIcon,
  Button,
  Center,
  Flex,
  Grid,
  Input,
  Switch,
  Text,
} from "@mantine/core";
import { IconMinus, IconPlus } from "@tabler/icons-react";
import { useReducer } from "react";
import { IData } from "./utils/types";
import { SuccessAlert, ErrorAlert } from "./Filters/ErrorSuccessAlert";
import useFilterFetch from "./utils/useFilterFetch";

function reducer(
  state: { active: boolean; tag: string; safe_list: string[] },
  action: { type: string; payload?: any }
) {
  switch (action.type) {
    case "SET_ACTIVE":
      return { ...state, active: action.payload };
    case "SET_TAG":
      return { ...state, tag: action.payload };
    case "ADD_SAFE_LIST":
      return { ...state, safe_list: [...state.safe_list, ""] }; // Add a new input
    case "REMOVE_SAFE_LIST":
      return {
        ...state,
        safe_list: state.safe_list.filter(
          (list, index) => index !== action.payload
        ),
      };
    case "SET_SAFE_LIST_ITEM":
      const newSafeList = [...state.safe_list];
      newSafeList[action.payload.index] = action.payload.value; // Update specific input
      return { ...state, safe_list: newSafeList };
    default:
      return state;
  }
}

export default function SpamSettings({
  filterData,
  fetchMailFilters,
}: {
  filterData: IData;
  fetchMailFilters: () => void;
}) {
  //Custom use fetch
  const { success, setSuccess, error, setError, fetchData } = useFilterFetch();

  const [state, dispatch] = useReducer(reducer, { ...filterData.spam });
  const safe_list_append = state.safe_list.length ? state.safe_list : [""];

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const data = {
      active: state.active,
      tag: state.tag,
      safe_list: state.safe_list.filter((val) => {
        const isValidEmailOrDomain =
          /^(?:[^\s@]+@[^\s@]+\.[^\s@]+|[^\s@]+\.[^\s@]+)$/.test(val);
        return isValidEmailOrDomain;
      }),
    };
    if (state.tag) {
      fetchData(JSON.stringify({ email: { ...filterData, spam: data } })).then(
        () => fetchMailFilters()
      );
    } else {
      setError(true);
      setSuccess(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid mt={15} mb={15}>
        <Grid.Col span={3}>
          <Text ta="center" fw={700}>
            状態
          </Text>
        </Grid.Col>
        <Grid.Col span={9}>
          <Switch
            name="active"
            checked={state.active}
            onChange={(event) =>
              dispatch({
                type: "SET_ACTIVE",
                payload: event.currentTarget.checked,
              })
            }
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <Text ta="center" fw={700}>
            タグ
          </Text>
        </Grid.Col>
        <Grid.Col span={9}>
          <Input
            name="tag"
            placeholder="[MEIWAKU]"
            value={state.tag}
            onChange={(e) =>
              dispatch({
                type: "SET_TAG",
                payload: e.currentTarget.value,
              })
            }
          />
        </Grid.Col>
        <Grid.Col span={3}>
          <Text ta="center" fw={700}>
            セーフリスト
          </Text>
        </Grid.Col>
        <Grid.Col span={9}>
          <Flex direction="column" gap={10}>
            {safe_list_append.map((item, index) => (
              <Flex key={index} align="center" gap={10}>
                <Input
                  name={`safe_list[${index}]`}
                  value={item}
                  onChange={(e) =>
                    dispatch({
                      type: "SET_SAFE_LIST_ITEM",
                      payload: { index, value: e.currentTarget.value },
                    })
                  }
                />
                {index < state.safe_list.length - 1 ? (
                  <ActionIcon
                    radius="lg"
                    onClick={() =>
                      dispatch({
                        type: "REMOVE_SAFE_LIST",
                        payload: index,
                      })
                    }
                  >
                    <IconMinus />
                  </ActionIcon>
                ) : (
                  <ActionIcon
                    radius="lg"
                    onClick={() => dispatch({ type: "ADD_SAFE_LIST" })}
                  >
                    <IconPlus />
                  </ActionIcon>
                )}
              </Flex>
            ))}
          </Flex>
        </Grid.Col>
      </Grid>
      <Center>
        <Button justify="center" type="submit">
          SPAMの設定更新
        </Button>
      </Center>
      {success && <SuccessAlert />}
      {error && <ErrorAlert />}
    </form>
  );
}
