import {
  Anchor,
  Title,
  Text,
  Container,
  Stepper,
  Group,
  Button,
} from "@mantine/core";
import classes from "./Register.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useIsAuthenticated } from "../utils/useIsAuthenticated";
import { useEffect, useState } from "react";
import { notifications } from "@mantine/notifications";
import FirstStep from "./FirstStep";
import FinalStep from "./FinalStep";
import SecondStep from "./SecondStep";

const callNotification = (message: string = "入力は不完全または無効です") => {
  notifications.show({
    title: "エラー",
    message: message,
  });
};

export default function Register() {
  const query = new URLSearchParams(useLocation().search);
  const id = query.get("amix");
  const key = query.get("key");
  const isAuthenticated = useIsAuthenticated();

  const [active, setActive] = useState(0);
  const nextStep = () => {
    let flag = false;

    //First slide
    if (active === 0) {
      const pattern = /^[a-zA-Z][a-zA-Z0-9-]{1,8}$/;
      const pattern2 = /^[a-zA-Z0-9-]{8,15}$/;
      if (!pattern.test(URLState[0][0]) || !pattern2.test(passwordState[0]))
        flag = true;
      else {
        fetch(
          "https://cp.amixcom.jp/api/mgmt_api/register.php?id=" +
            id +
            "&key=" +
            key +
            "&username=" +
            URLState[0][0],
          {
            method: "GET",
          }
        )
          .then((resp) => {
            if (resp.ok) {
              return resp.text();
            }
          })
          .then((resp) => {
            if (resp) {
              const totalRow = parseInt(resp);
              if (totalRow === 0) {
                setActive((current) => current + 1);
              } else {
                callNotification("そのユーザーは既に存在します");
                return;
              }
            }
          });
      }
    }

    //Register to Database
    else if (active === 1) {
      fetch("https://cp.amixcom.jp/api/mgmt_api/register.php", {
        method: "POST",
        body: JSON.stringify({
          host: URLState[0][0],
          base_domain: URLState[0][1],
          password: passwordState[0],
          id: id,
          key: key,
        }),
      }).then((resp) => {
        if (resp.ok) {
          setActive((current) => current + 1);
          return resp.text();
        } else throw new Error(resp.status.toString());
      });
      // .then((resp) => console.log(resp));
    }

    //If flag is true, trigger error notification
    if (flag) {
      callNotification();
      return;
    }
  };

  const prevStep = () =>
    setActive((current) => (current > 0 ? current - 1 : current));
  const navigate = useNavigate();

  //Register data handling
  const URLState = useState<string[]>(["", "enat.jp"]);
  const emailNameState = useState<string>("");
  const passwordState = useState<string>("");

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/");
      return;
    }
    if (!id || !key) {
      navigate("/login");
      return;
    }
    // fetch(
    //   "https://cp.amixcom.jp/api/mgmt_api/check_user.php?id=" +
    //     id +
    //     "&key=" +
    //     key
    // )
    //   .then((resp: Response) => {
    //     if (!resp.ok) navigate("/login");
    //     return resp.text();
    //   })
    //   .then((resp) => {
    //     if (resp === "1") {
    //       navigate("/login");
    //     }
    //   });
  }, [navigate, id, key, isAuthenticated]);

  const redirectLogin = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    navigate("/login");
  };

  return (
    <Container size={800} my={80}>
      <Title ta="center" className={classes.title} c={"#C92A2A"}>
        ami:tool
      </Title>
      <Text ta="center" c="dimmed">
        <Anchor
          component="button"
          type="button"
          size="xs"
          onClick={redirectLogin}
        >
          オンラインサインイン
        </Anchor>
      </Text>
      <Stepper active={active} mt={30} size="xs">
        <Stepper.Step label="STEP 1" description="サブドメインの入力">
          <FirstStep URLState={URLState} passwordState={passwordState} />
        </Stepper.Step>
        <Stepper.Step label="STEP 2" description="お客様情報の確認">
          <SecondStep
            URL={URLState[0]}
            emailName={emailNameState[0]}
            password={passwordState[0]}
          />
        </Stepper.Step>
        <Stepper.Completed>
          <FinalStep />
        </Stepper.Completed>
      </Stepper>
      {active < 2 && (
        <Group justify="center" mt="xl">
          <Button variant="default" onClick={prevStep}>
            戻る
          </Button>
          <Button onClick={nextStep}>次へ進む</Button>
        </Group>
      )}
    </Container>
  );
}
