import { Alert } from "@mantine/core";
import { IconInfoCircle } from "@tabler/icons-react";
import React from "react";

export function ErrorAlert() {
  return (
    <Alert
      variant="light"
      color="red"
      title="無効な入力です"
      icon={<IconInfoCircle />}
      mt={20}
      mx={15}
    >
      無効な入力です。正しいインプットを入力してください。
    </Alert>
  );
}

export function SuccessAlert() {
  return (
    <Alert
      variant="light"
      color="green"
      title="更新成功しました"
      icon={<IconInfoCircle />}
      mt={20}
      mx={15}
    >
      フィルター設定が正常に更新されました
    </Alert>
  );
}
