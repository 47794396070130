import { Title, Text, Button, Container, Group } from "@mantine/core";
import classes from "./NotFoundTitle.module.css";
import { useNavigate } from "react-router-dom";

export default function NotFoundTitle() {
  const navigate = useNavigate();
  const navigateToHome = () => {
    navigate("/");
  };

  return (
    <Container className={classes.root}>
      <div className={classes.label}>404</div>
      <Title className={classes.title}>ページが見つかりません</Title>
      <Text c="dimmed" size="lg" ta="center" className={classes.description}>
        残念ながら、これは404ページだけです。アドレスを間違えたか、ページが別のURLに移動された可能性があります。
      </Text>
      <Group justify="center">
        <Button variant="subtle" size="md" onClick={navigateToHome}>
          ホームページに戻る
        </Button>
      </Group>
    </Container>
  );
}
