import { useEffect } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

export default function Logout() {
  // eslint-disable-next-line
  const [cookies, setCookies, removeCookies] = useCookies();
  const navigate = useNavigate();
  useEffect(() => {
    //remove cookies
    removeCookies("userID");
    removeCookies("username");
    removeCookies("domain");
    removeCookies("mailID");
    removeCookies("token");
    navigate("/login");
  }, [navigate, removeCookies]);
  return null;
}
