import {
  ActionIcon,
  Button,
  Center,
  Flex,
  Grid,
  Input,
  NativeSelect,
  Text,
} from "@mantine/core";
import { IconMinus, IconPlus } from "@tabler/icons-react";
import React, { useReducer } from "react";
import { IData } from "../utils/types";
import { SuccessAlert, ErrorAlert } from "./ErrorSuccessAlert";
import useFilterFetch from "../utils/useFilterFetch";

const initialState = {
  status: "01",
  emails: [""],
};

function reducer(
  state: typeof initialState,
  action: { type: string; payload?: any }
) {
  switch (action.type) {
    case "SET_REMAIN":
      return { ...state, status: action.payload };
    case "ADD_EMAIL_LIST":
      return { ...state, emails: [...state.emails, ""] };
    case "REMOVE_EMAIL_LIST":
      return {
        ...state,
        emails: state.emails.filter((list, index) => index !== action.payload),
      };
    case "SET_EMAIL_LIST_ITEM":
      const newEmailList = [...state.emails];
      newEmailList[action.payload.index] = action.payload.value; // Update specific input
      return { ...state, emails: newEmailList };
    default:
      return state;
  }
}

export default function ForwardMails({
  filterData,
  fetchMailFilters,
}: {
  filterData: IData;
  fetchMailFilters: () => void;
}) {
  //Custom use fetch
  const { success, error, fetchData } = useFilterFetch();

  const [state, dispatch] = useReducer(reducer, {
    ...(filterData.filters && filterData.filters.transfer
      ? filterData.filters.transfer
      : initialState),
  });

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    //01:残す 02:残さない
    const data = {
      status: state.status,
      emails: state.emails.filter((val) => {
        const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(val);
        return isValidEmail;
      }),
    };
    if (data.emails.length) {
      fetchData(
        JSON.stringify({
          email: {
            ...filterData,
            filter_revision: 0,
            filters: {
              ...filterData.filters,
              transfer: data,
            },
          },
        })
      ).then(() => fetchMailFilters());
    } else {
      fetchData(
        JSON.stringify({
          email: {
            ...filterData,
            filter_revision: 0,
            filters: {
              ...filterData.filters,
              transfer: undefined,
            },
          },
        })
      ).then(() => fetchMailFilters());
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid mt={15} mb={15}>
        <Grid.Col span={5}>
          <Text ta="center" fw={700} mt={5}>
            受信したメールをサーバに
          </Text>
        </Grid.Col>
        <Grid.Col span={7}>
          <NativeSelect
            name="remain"
            value={state.status}
            data={[
              { label: "残す", value: "01" },
              { label: "残さない", value: "02" },
            ]}
            onChange={(e) =>
              dispatch({
                type: "SET_REMAIN",
                payload: e.currentTarget.value,
              })
            }
          />
        </Grid.Col>
        <Grid.Col span={5}>
          <Text ta="center" fw={700} mt={5}>
            転送先アドレス
          </Text>
        </Grid.Col>
        <Grid.Col span={7}>
          <Flex direction="column" gap={10}>
            {state.emails.map((item, index) => (
              <Flex key={index} align="center" gap={10}>
                <Input
                  name={`email_list[${index}]`}
                  placeholder="tensou@email.com"
                  value={item}
                  onChange={(e) =>
                    dispatch({
                      type: "SET_EMAIL_LIST_ITEM",
                      payload: { index, value: e.currentTarget.value },
                    })
                  }
                />
                {index < state.emails.length - 1 ? (
                  <ActionIcon
                    radius="lg"
                    onClick={() =>
                      dispatch({
                        type: "REMOVE_EMAIL_LIST",
                        payload: index,
                      })
                    }
                  >
                    <IconMinus />
                  </ActionIcon>
                ) : (
                  <ActionIcon
                    radius="lg"
                    onClick={() => dispatch({ type: "ADD_EMAIL_LIST" })}
                  >
                    <IconPlus />
                  </ActionIcon>
                )}
              </Flex>
            ))}
          </Flex>
        </Grid.Col>
      </Grid>
      <Center>
        <Button justify="center" type="submit">
          転送の設定更新
        </Button>
      </Center>
      {success && <SuccessAlert />}
      {error && <ErrorAlert />}
    </form>
  );
}
