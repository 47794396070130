import {
  Button,
  Container,
  Flex,
  Paper,
  Space,
  Stack,
  Text,
  ThemeIcon,
  Title,
} from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";

export default function FinalStep() {
  const navigate = useNavigate();
  return (
    <Container size={800}>
      <Paper withBorder shadow="md" p={30} mt={10} radius="md">
        <Flex
          justify="center"
          align="center"
          direction="column"
          bg="#c92a2a"
          py={35}
        >
          <ThemeIcon radius="xl" size={70} variant="white">
            <IconCheck style={{ width: "70%", height: "70%" }} />
          </ThemeIcon>
          <Text c="white" fw={600} size="xl">
            SUCCESS
          </Text>
        </Flex>
        <Space h="xl" />
        <Stack align="center">
          <Title order={2} c="#c92a2a" ta="center">
            申込が完了しました。
            <br />
            設定を行いますので２営業日後からご利用いただけます。
          </Title>
          <Button radius="xl" onClick={() => navigate("/login")}>
            ログインページに戻る
          </Button>
        </Stack>
        <Space h="md" />
      </Paper>
    </Container>
  );
}
