import {
  Paper,
  Title,
  Text,
  Button,
  Container,
  Center,
  Space,
  ThemeIcon,
  PasswordInput,
} from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";
import classes from "./PasswordReset.module.css";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { useIsAuthenticated } from "../utils/useIsAuthenticated";

export default function NewPassword() {
  const navigate = useNavigate();
  const [sendCode, setSendCode] = useState<string | null>(null);
  const [passwordInput, setPasswordInput] = useState("");
  const [error, setError] = useState(false);
  const [formHide, setFormHide] = useState(false);

  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (isAuthenticated) navigate("/");
    const urlParams = new URLSearchParams(window.location.search);
    setSendCode(urlParams.get("send_code"));
  }, [navigate, isAuthenticated]);

  const confirmReset = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (passwordInput.length >= 8) {
      setError(false);
      fetch(
        `https://ami-test.cocona.jp/api/reset_password.php?code=${sendCode}`,
        {
          method: "PUT",
          body: JSON.stringify({
            new_password: passwordInput,
          }),
        }
      ).then((resp) => {
        if (resp.ok) setFormHide(true);
        else setError(true);
      });
    } else setError(true);
  };

  const backToLogin = () => {
    navigate("/login");
  };

  return (
    <Container size={460} my={80}>
      <Title className={classes.title} ta="center">
        パスワードをリセットする
      </Title>
      <Text c="dimmed" fz="sm" ta="center">
        新しいパスワードを入力してください
      </Text>

      {!formHide ? (
        <form onSubmit={confirmReset}>
          <Paper withBorder shadow="md" p={30} radius="md" mt="xl">
            <PasswordInput
              name="new_password"
              label="新しいパスワード"
              placeholder="パスワード"
              value={passwordInput}
              onChange={(event) => setPasswordInput(event.currentTarget.value)}
              error={error ? "パスワードの最小長さは８桁です" : false}
            />
            <Space h="md" />
            <Center>
              <Button className={classes.control} type="submit">
                パスワードをリセットする
              </Button>
            </Center>
          </Paper>
        </form>
      ) : (
        <Paper withBorder shadow="md" p={30} radius="md" mt="xl">
          <Center>
            <ThemeIcon radius="xl" size="xl" color="teal">
              <IconCheck />
            </ThemeIcon>
          </Center>
          <Space h="md" />
          <Text ta="center">パスワードをリセットしました。</Text>
          <Space h="md" />
          <Center>
            <Button onClick={backToLogin}>ログインページに戻ります</Button>
          </Center>
        </Paper>
      )}
    </Container>
  );
}
