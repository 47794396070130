import { Button, Center, Divider, Flex, Text, ThemeIcon } from "@mantine/core";
import { IconInfoCircle } from "@tabler/icons-react";
import React from "react";
import { useCookies } from "react-cookie";
import { useClearCookies } from "../utils/useIsAuthenticated";
import { notifications } from "@mantine/notifications";

interface Props {
  oldPass: string;
  newPass: string;
  setOldPass: React.Dispatch<React.SetStateAction<string>>;
  setNewPass: React.Dispatch<React.SetStateAction<string>>;
  close: () => void;
}

export default function ModalEditAccount({
  oldPass,
  newPass,
  setOldPass,
  setNewPass,
  close,
}: Props) {
  const [cookies] = useCookies();
  const clearCookies = useClearCookies();

  const confirmButtonHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    fetch(
      `https://cp.amixcom.jp/api/mgmt_api/change_password.php?user_id=${cookies.userID}`,
      {
        method: "PUT",
        headers: {
          "x-auth-token": cookies.token,
        },
        body: JSON.stringify({
          user: cookies.username,
          domain: cookies.domain,
          old_password: oldPass,
          new_password: newPass,
        }),
      }
    ).then((response) => {
      if (response.ok)
        notifications.show({
          color: "green",
          title: "パスワード変更",
          message: "パスワード変更しました。",
        });
      else if (response.status === 403) {
        clearCookies();
        throw new Error();
      } else
        notifications.show({
          color: "red",
          title: "パスワード変更",
          message: "パスワードの変更に失敗しました。",
        });
    });
    close();
    setOldPass("");
    setNewPass("");
  };

  return (
    <form onSubmit={confirmButtonHandler}>
      <Flex gap={10}>
        <ThemeIcon radius="xl">
          <IconInfoCircle style={{ width: "70%", height: "70%" }} />
        </ThemeIcon>
        <Text fw={700} size="lg">
          パスワード変更
        </Text>
      </Flex>
      <Center m={20}>
        <Text>
          パスワード変更を削除しますか？
          <br />
          よろしければ「確認」ボタンを押してください。
        </Text>
      </Center>
      <Divider my="sm" variant="dotted" />
      <Flex justify="flex-end" gap={10}>
        <Button type="submit">確認</Button>
        <Button variant="outline" onClick={close}>
          キャンセル
        </Button>
      </Flex>
    </form>
  );
}
