import {
  ActionIcon,
  Button,
  Center,
  Divider,
  Flex,
  Modal,
  PasswordInput,
  Radio,
  Text,
  ThemeIcon,
} from "@mantine/core";
import React, { useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { useClearCookies } from "../utils/useIsAuthenticated";
import { IconArrowBack, IconInfoCircle } from "@tabler/icons-react";
import { notifications } from "@mantine/notifications";
import { useDisclosure } from "@mantine/hooks";

export default function Kaiyaku() {
  const navigate = useNavigate();
  const [opened, { open, close }] = useDisclosure(false);

  //eslint-disable-next-line
  const [cookies, _, removeCookies] = useCookies();
  const clearCookies = useClearCookies();

  const [value, setValue] = useState("now");
  const [password, setPassword] = useState("");
  const [deleteDone, setDeleteDone] = useState(false);

  const openModal = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (password !== "") {
      open();
    } else {
      notifications.show({
        color: "red",
        title: "エラー",
        message: "パスワードを入力してください。",
      });
    }
  };

  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const post_data = {
      user_id: cookies.userID,
      password: password,
      time: value,
    };
    fetch(
      "https://cp.amixcom.jp/api/mgmt_api/kaiyaku.php?user_id=" +
        cookies.userID,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": cookies.token,
        },
        body: JSON.stringify(post_data),
      }
    )
      .then((res) => {
        if (res.status === 401) {
          return Promise.reject("パスワードが間違っています");
        }
        if (res.status === 200) {
          setDeleteDone(true);
        }
        return res.text();
      })
      .catch((err) => {
        notifications.show({
          color: "red",
          title: "エラー",
          message: err,
        });
      });
  };

  const finalConfirm = () => {
    close();
    if (value === "now") {
      clearCookies();
      navigate("/");
    } else {
      setDeleteDone(false);
    }
  };

  return (
    <>
      <Modal opened={opened} onClose={close} centered withCloseButton={false}>
        {deleteDone ? (
          <>
            <Flex gap={10} align="center" justify="center">
              <ThemeIcon radius="xl">
                <IconInfoCircle style={{ width: "70%", height: "70%" }} />
              </ThemeIcon>
              <Text fw={700} size="lg">
                解約承りました。
              </Text>
            </Flex>
            <Center mt={20}>
              <Button onClick={finalConfirm}>閉じる</Button>
            </Center>
          </>
        ) : (
          <form onSubmit={submitHandler}>
            <Flex gap={10}>
              <ThemeIcon radius="xl">
                <IconInfoCircle style={{ width: "70%", height: "70%" }} />
              </ThemeIcon>
              <Text fw={700} size="lg">
                解約
              </Text>
            </Flex>
            <Text m={20}>
              ・データは全て削除され復旧できません。{<br />}
              ・解約申請後の解約キャンセルは承りかねます。{<br />}
              ・同じドメイン名を再取得できないことがあります。
            </Text>
            <Flex justify="flex-end" gap={10}>
              <Button type="submit">同意して解約する</Button>
              <Button variant="outline" onClick={close}>
                キャンセル
              </Button>
            </Flex>
          </form>
        )}
      </Modal>

      {/* Main Content */}
      <Center mt={50}>
        <Flex align="center" gap={125}>
          <ActionIcon size="xl" variant="outline" onClick={() => navigate(-1)}>
            <IconArrowBack />
          </ActionIcon>
          <Text
            style={{ textAlign: "center", fontWeight: 600, fontSize: 50 }}
            mt={10}
          >
            解約申請
          </Text>
          <div />
        </Flex>
      </Center>
      <Center m={30} mb={150}>
        <form onSubmit={openModal}>
          <Center my={20}>
            <Text>
              １）解約しますか？解約の種類を選択して「確認」ボタンを押してください。
            </Text>
          </Center>
          <Flex justify="flex-start" direction="column" gap={20} ml={20}>
            <Radio
              value="now"
              label="今すぐ解約する（年間費用の払い戻しはされません）"
              onChange={(e) => setValue(e.target.value)}
              checked={value === "now"}
            />
            <Radio
              value="later"
              label="契約満了日に解約する"
              onChange={(e) => setValue(e.target.value)}
              checked={value === "later"}
            />
          </Flex>
          <Divider my="sm" variant="dotted" />
          <Text>２）確認のためにパスワードを入力してください。</Text>
          <PasswordInput
            mx={20}
            my={5}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Divider my="sm" variant="dotted" />
          <Center mt={20}>
            <Button type="submit">確認</Button>
          </Center>
        </form>
      </Center>
    </>
  );
}
