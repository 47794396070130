import { Tabs } from "@mantine/core";
import {
  IconCornerDownRightDouble,
  IconMailOff,
  IconNotification,
  IconRepeat,
} from "@tabler/icons-react";
import ForwardMails from "./Filters/ForwardMails";
import RejectMails from "./Filters/RejectMails";
import AutoReplyMails from "./Filters/AutoReplyMails";
import NotifyMails from "./Filters/NotifyMails";
import { IData } from "./utils/types";

export default function FilterSettings({
  filterData,
  fetchMailFilters,
}: {
  filterData: IData;
  fetchMailFilters: () => void;
}) {
  const iconStyle = { width: 20, height: 20 };
  return (
    <Tabs defaultValue="redirect" mt={15}>
      <Tabs.List grow>
        <Tabs.Tab
          value="redirect"
          leftSection={<IconCornerDownRightDouble style={iconStyle} />}
        >
          転送
        </Tabs.Tab>
        <Tabs.Tab value="drop" leftSection={<IconMailOff style={iconStyle} />}>
          受信拒否
        </Tabs.Tab>
        <Tabs.Tab
          value="vacation"
          leftSection={<IconRepeat style={iconStyle} />}
        >
          自動返信
        </Tabs.Tab>
        <Tabs.Tab
          value="notify"
          leftSection={<IconNotification style={iconStyle} />}
        >
          メール通知
        </Tabs.Tab>
      </Tabs.List>
      <Tabs.Panel value="redirect">
        <ForwardMails
          filterData={filterData}
          fetchMailFilters={fetchMailFilters}
        />
      </Tabs.Panel>
      <Tabs.Panel value="drop">
        <RejectMails
          filterData={filterData}
          fetchMailFilters={fetchMailFilters}
        />
      </Tabs.Panel>
      <Tabs.Panel value="vacation">
        <AutoReplyMails
          filterData={filterData}
          fetchMailFilters={fetchMailFilters}
        />
      </Tabs.Panel>
      <Tabs.Panel value="notify">
        <NotifyMails
          filterData={filterData}
          fetchMailFilters={fetchMailFilters}
        />
      </Tabs.Panel>
    </Tabs>
  );
}
