import { Modal, ScrollArea, Tabs, Text } from "@mantine/core";
import { IconKey, IconMailCancel, IconMailCheck } from "@tabler/icons-react";
import SpamSettings from "./SpamSettings";
import FilterSettings from "./FilterSettings";
import { useCookies } from "react-cookie";
import { useEffect, useState } from "react";
import { useClearCookies } from "../utils/useIsAuthenticated";
import { IData } from "./utils/types";
import ChangePassword from "./ChangePassword";

interface Props {
  emailId: number | null;
  opened: boolean;
  close: () => void;
}

export default function MailFilter({ emailId, opened, close }: Props) {
  const iconStyle = { width: 20, height: 20 };
  const [cookies] = useCookies();
  const clearCookies = useClearCookies();
  const [filterData, setFilterData] = useState<IData | null>(null);

  const fetchMailFilters = () => {
    fetch(
      `https://cp.amixcom.jp/api/mgmt_api/email_filter.php?mail_id=${emailId}&user_id=${cookies.userID}`,
      {
        method: "GET",
        headers: {
          "x-auth-token": cookies.token,
        },
      }
    )
      .then(async (response) => {
        if (response.status === 403) {
          clearCookies();
          throw new Error();
        } else if (response.status === 500) {
          const errorMessage = await response.text();
          throw new Error(errorMessage);
        } else return response.json();
      })
      .then((data) => {
        setFilterData(data.emails[0]);
      });
  };

  useEffect(() => {
    if (opened) fetchMailFilters();
    // eslint-disable-next-line
  }, [opened]);

  const closeModal = () => {
    setFilterData(null);
    close();
  };

  return (
    <Modal
      opened={opened}
      onClose={closeModal}
      closeOnClickOutside={false}
      title={
        <Text ta="center" size="xl" fw={700}>
          メールの設定
        </Text>
      }
      centered
      size="xl"
      scrollAreaComponent={ScrollArea.Autosize}
    >
      {filterData ? (
        <Tabs defaultValue="change_password">
          <Tabs.List grow>
            <Tabs.Tab
              value="change_password"
              leftSection={<IconKey style={iconStyle} />}
            >
              パスワード変更
            </Tabs.Tab>
            <Tabs.Tab
              value="spam_mails"
              leftSection={<IconMailCancel style={iconStyle} />}
            >
              SPAM判定
            </Tabs.Tab>
            <Tabs.Tab
              value="filter_mails"
              leftSection={<IconMailCheck style={iconStyle} />}
            >
              受信メールフィルタ
            </Tabs.Tab>
          </Tabs.List>

          {filterData && (
            <>
              <Tabs.Panel value="change_password">
                <ChangePassword filterData={filterData} />
              </Tabs.Panel>
              <Tabs.Panel value="spam_mails">
                <SpamSettings
                  filterData={filterData}
                  fetchMailFilters={fetchMailFilters}
                />
              </Tabs.Panel>
              <Tabs.Panel value="filter_mails">
                <FilterSettings
                  filterData={filterData}
                  fetchMailFilters={fetchMailFilters}
                />
              </Tabs.Panel>
            </>
          )}
        </Tabs>
      ) : (
        <></>
      )}
    </Modal>
  );
}
