import {
  Button,
  Card,
  Center,
  Grid,
  Input,
  Select,
  Space,
  Table,
  Text,
  Textarea,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import React, { useState } from "react";
import { useCookies } from "react-cookie";

export default function Inquiry() {
  //cookies handling
  const [cookies] = useCookies();

  const [email, setEmail] = useState<string>("");
  const [topic, setTopic] = useState<string | null>("");
  const [comment, setComment] = useState<string>("");
  const [OS, setOS] = useState<string | null>("");
  const [browser, setBrowser] = useState<string | null>("");

  const inputCheck = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (
      email === "" ||
      !emailRegex.test(email) ||
      topic === "" ||
      comment === "" ||
      OS === "" ||
      browser === ""
    ) {
      notifications.show({
        color: "red",
        title: "入力エラー",
        message: "全ての項目を入力してください",
      });
      return false;
    }
    return true;
  };

  const submitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!inputCheck()) return;
    fetch(
      `https://cp.amixcom.jp/api/mgmt_api/contact.php?user_id=${cookies.userID}`,
      {
        method: "POST",
        headers: {
          "x-auth-token": cookies.token,
        },
        body: JSON.stringify({
          email,
          user: cookies.username,
          domain: cookies.domain,
          topic,
          comment,
          OS,
          browser,
        }),
      }
    ).then((response) => {
      if (response.ok)
        notifications.show({
          color: "green",
          title: "メール送信",
          message: "メール送信しました",
        });
    });
  };

  const resetHandler = () => {
    setTopic(null);
    setComment("");
    setOS(null);
    setBrowser(null);
  };

  return (
    <>
      <Text
        style={{ textAlign: "center", fontWeight: 600, fontSize: 50 }}
        mt={10}
      >
        お問い合わせ
      </Text>
      <Center m={30}>
        <Grid>
          <Grid.Col span={{ base: 12, md: 6 }}>
            <Card
              padding="sm"
              withBorder
              style={{
                minWidth: "15vw",
              }}
            >
              <Card.Section
                style={{
                  textAlign: "center",
                  background: "rgb(201,42,42)",
                  color: "white",
                  fontWeight: 700,
                  padding: 5,
                }}
              >
                ご意見ご要望
              </Card.Section>
              <form onSubmit={submitHandler}>
                <Table>
                  <Table.Tbody>
                    <Table.Tr>
                      <Table.Td>返信先メールアドレス</Table.Td>
                      <Table.Td>
                        <Input
                          name="email"
                          value={email}
                          onChange={(event) =>
                            setEmail(event.currentTarget.value)
                          }
                        />
                      </Table.Td>
                    </Table.Tr>
                    <Table.Tr>
                      <Table.Td>何について？</Table.Td>
                      <Table.Td>
                        <Select
                          placeholder="選択してください"
                          name="topic"
                          data={[
                            "メールの管理",
                            "ウェブメール",
                            "サポートについて",
                            "料金について",
                            "全般",
                            "その他",
                          ]}
                          value={topic}
                          onChange={(val) => (val ? setTopic(val) : null)}
                        />
                      </Table.Td>
                    </Table.Tr>
                    <Table.Tr>
                      <Table.Td>ご意見ご要望</Table.Td>
                      <Table.Td>
                        <Textarea
                          name="comment"
                          autosize
                          minRows={10}
                          value={comment}
                          onChange={(event) =>
                            setComment(event.currentTarget.value)
                          }
                        />
                      </Table.Td>
                    </Table.Tr>
                    <Table.Tr>
                      <Table.Td>ご利用のOS</Table.Td>
                      <Table.Td>
                        <Select
                          placeholder="選択してください"
                          name="os"
                          data={[
                            "Windows 11",
                            "Windows 10",
                            "Windows 8(8.1)",
                            "Windows 7 以前",
                            "MacOS",
                            "その他",
                          ]}
                          value={OS}
                          onChange={(val) => (val ? setOS(val) : null)}
                        />
                      </Table.Td>
                    </Table.Tr>
                    <Table.Tr>
                      <Table.Td>ご利用のブラウザ</Table.Td>
                      <Table.Td>
                        <Select
                          placeholder="選択してください"
                          name="browser"
                          data={[
                            "Google Chrome",
                            "Firefox",
                            "Safari",
                            "Microsoft Edge",
                            "Internet Explorer",
                            "その他",
                          ]}
                          value={browser}
                          onChange={(val) => (val ? setBrowser(val) : null)}
                        />
                      </Table.Td>
                    </Table.Tr>
                  </Table.Tbody>
                </Table>
                <Space h="md" />
                <Center>
                  <Button type="submit">確認</Button>
                  <Space w="md" />
                  <Button variant="outline" onClick={resetHandler}>
                    リセット
                  </Button>
                </Center>
              </form>
            </Card>
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 6 }}>
            <Card
              padding="sm"
              withBorder
              style={{
                minWidth: "15vw",
              }}
            >
              <Card.Section
                style={{
                  textAlign: "center",
                  background: "rgb(201,42,42)",
                  color: "white",
                  fontWeight: 700,
                  padding: 5,
                }}
              >
                ご意見ご要望について
              </Card.Section>
              <Text style={{ textAlign: "left", margin: 10 }}>
                寄せられた内容を十分検討し、今後のよりよいサービス及び新しいコンテンツの充実に役立てます。
                <br />
                いただいた内容全てを反映できるとは限りませんので、予めご了承ください。
              </Text>
            </Card>
          </Grid.Col>
        </Grid>
      </Center>
    </>
  );
}
