import {
  Menu,
  Group,
  Center,
  Burger,
  Container,
  Title,
  Drawer,
  Stack,
  Text,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import {
  IconChevronDown,
  IconInfoHexagon,
  IconLogout,
  IconMail,
  IconUser,
  // IconWorldWww,
} from "@tabler/icons-react";
import classes from "./HeaderMenu.module.css";
import { Link } from "react-router-dom";

const links = [
  { link: "/mail", label: "メールの管理", logo: IconMail },
  // { link: "/web", label: "ウェブの管理", logo: IconWorldWww },
  {
    link: "#",
    label: "ユーザ",
    links: [
      { link: "/inquire", label: "問い合わせ", logo: IconInfoHexagon },
      { link: "/account", label: "管理者情報", logo: IconUser },
      { link: "/logout", label: "ログアウト", logo: IconLogout },
    ],
  },
];

export default function HeaderMenu() {
  const [opened, { toggle }] = useDisclosure(false);

  const items = links.map((link) => {
    const menuItems = link.links?.map((item) => (
      <Menu.Item key={item.link}>
        <Link to={item.link} className={classes.link}>
          <Text c="#C92A2A" size="sm">
            {item.label}
          </Text>
        </Link>
      </Menu.Item>
    ));

    if (menuItems) {
      return (
        <Menu
          key={link.label}
          trigger="hover"
          transitionProps={{ exitDuration: 0 }}
          withinPortal
        >
          <Menu.Target>
            <Link to={link.link} className={classes.link}>
              <Center>
                <span className={classes.linkLabel}>{link.label}</span>
                <IconChevronDown size="0.9rem" stroke={1.5} />
              </Center>
            </Link>
          </Menu.Target>
          <Menu.Dropdown>{menuItems}</Menu.Dropdown>
        </Menu>
      );
    }

    return (
      <Link key={link.label} to={link.link} className={classes.link}>
        {link.label}
      </Link>
    );
  });

  const burgerItems = links.map((link) => {
    if (link.links)
      return link.links.map((item) => (
        <Link
          key={item.label}
          to={item.link}
          className={classes.link}
          style={{ color: "#C92A2A" }}
        >
          <Group>
            <item.logo />
            <Text>{item.label}</Text>
          </Group>
        </Link>
      ));
    return (
      <Link
        key={link.label}
        to={link.link}
        className={classes.link}
        style={{ color: "#C92A2A" }}
      >
        <Group>
          <link.logo />
          <Text>{link.label}</Text>
        </Group>
      </Link>
    );
  });

  return (
    <header className={classes.header}>
      <Container size="md">
        <div className={classes.inner}>
          <Link to="/" className={classes.link}>
            <Title order={1} style={{ color: "white", fontWeight: 750 }}>
              ami:tool
            </Title>
          </Link>
          <Group gap={30} visibleFrom="sm">
            {items}
          </Group>
          <Burger
            opened={opened}
            onClick={toggle}
            size="sm"
            hiddenFrom="sm"
            color="white"
          />
          <Drawer
            opened={opened}
            onClose={toggle}
            className={classes.drawer}
            size="xs"
          >
            <Stack className={classes.stack} gap="xl">
              {burgerItems}
            </Stack>
          </Drawer>
        </div>
      </Container>
    </header>
  );
}
