import {
  Alert,
  Button,
  Card,
  Center,
  Grid,
  Modal,
  PasswordInput,
  Space,
  Table,
  Text,
} from "@mantine/core";
import { useEffect, useState } from "react";
import { useDisclosure } from "@mantine/hooks";
import { useCookies } from "react-cookie";
import ModalEditAccount from "./ModalEdit";
import { useClearCookies } from "../utils/useIsAuthenticated";
import { useNavigate } from "react-router-dom";
import { notifications } from "@mantine/notifications";
import { IconInfoCircle } from "@tabler/icons-react";

interface UserData {
  cPaymentKbn: string;
  user: {
    usr_ftpid: string;
    usr_userid: string;
    usr_password: string;
    usr_kanji01: string;
    usr_kanji02: string;
    usr_kana01: string;
    usr_kana02: string;
    usr_maddress: string;
    usr_birthday: string;
    usr_zip01: string;
    usr_zip02: string;
    usr_prefecture: string;
    usr_address: string;
    usr_tel01: string;
    usr_tel02: string;
    usr_tel03: string;
    usr_fax01: string;
    usr_fax02: string;
    usr_fax03: string;
    usr_cardkind: string | null;
    usr_cardname: string;
    usr_cardnumber: string;
    usr_carddate: string | null;
    sys_fqdn: string;
    usr_oracleid: number;
    offer_time: string;
    start_time: string;
    finish_time: string;
    pause_flag: number;
    leave_flag: number;
    update_time: string;
    amix_account: string;
    mail_id: number;
    domain_id: number;
    usr_note: string;
  };
}

export default function Account() {
  const [cookies] = useCookies();
  const clearCookies = useClearCookies();
  const navigate = useNavigate();

  const [opened, { open, close }] = useDisclosure(false);
  const [modalType, setModalType] = useState("");

  const [oldPass, setOldPass] = useState("");
  const [newPass, setNewPass] = useState("");

  const [userData, setUserData] = useState<UserData | null>(null);

  const verifyPassword = (password: string) => {
    if (password.length < 8 || password.length > 15) {
      notifications.show({
        color: "red",
        title: "パスワードの変更",
        message: "パスワードは8文字以上、15文字以内で入力してください。",
      });
      return;
    }
    open();
    setModalType("change");
  };

  useEffect(() => {
    if (cookies.userID)
      fetch(
        `https://cp.amixcom.jp/api/mgmt_api/user.php?user_id=${cookies.userID}`,
        {
          method: "GET",
          headers: {
            "x-auth-token": cookies.token,
          },
        }
      )
        .then((response) => {
          if (response.status === 403) {
            clearCookies();
            throw new Error("Unauthorized");
          } else if (!response.ok) throw new Error("Failed to fetch data");
          else return response.json();
        })
        .then((data) => {
          setUserData(data);
        })
        .catch((err) => {});
    // eslint-disable-next-line
  }, [cookies.userID, cookies.token]);

  return (
    <>
      {userData && (
        <>
          <Modal
            opened={opened}
            onClose={close}
            centered
            withCloseButton={false}
          >
            {modalType === "change" && (
              <ModalEditAccount
                oldPass={oldPass}
                newPass={newPass}
                setOldPass={setOldPass}
                setNewPass={setNewPass}
                close={close}
              />
            )}
          </Modal>

          {/* Main Content */}
          <Text
            style={{ textAlign: "center", fontWeight: 600, fontSize: 50 }}
            mt={10}
          >
            管理者情報
          </Text>
          <Center m={30}>
            <Grid>
              <Grid.Col span={{ base: 12, md: 6 }}>
                <Card
                  padding="sm"
                  withBorder
                  style={{
                    minWidth: "25vw",
                  }}
                >
                  <Card.Section
                    style={{
                      textAlign: "center",
                      background: "rgb(201,42,42)",
                      color: "white",
                      fontWeight: 700,
                      padding: 5,
                    }}
                  >
                    基本情報
                  </Card.Section>
                  <Table>
                    <Table.Tbody>
                      <Table.Tr>
                        <Table.Td>お客様番号</Table.Td>
                        <Table.Td>{userData.user.amix_account}</Table.Td>
                      </Table.Tr>
                      <Table.Tr>
                        <Table.Td>ユーザー名 </Table.Td>
                        <Table.Td>{userData.user.usr_userid}</Table.Td>
                      </Table.Tr>
                      <Table.Tr>
                        <Table.Td>ログインパスワード </Table.Td>
                        <Table.Td>********</Table.Td>
                      </Table.Tr>
                    </Table.Tbody>
                  </Table>
                </Card>
                <Space h="md" />
                <Card
                  padding="sm"
                  withBorder
                  style={{
                    minWidth: "25vw",
                  }}
                >
                  <Card.Section
                    style={{
                      textAlign: "center",
                      background: "rgb(201,42,42)",
                      color: "white",
                      fontWeight: 700,
                      padding: 5,
                    }}
                  >
                    パスワード変更
                  </Card.Section>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      if (oldPass && newPass) {
                        verifyPassword(newPass);
                      }
                    }}
                  >
                    <Table>
                      <Table.Tbody>
                        <Table.Tr>
                          <Table.Td>旧パスワード</Table.Td>
                          <Table.Td>
                            <PasswordInput
                              name="old_password"
                              value={oldPass}
                              onChange={(event) =>
                                setOldPass(event.currentTarget.value)
                              }
                            />
                          </Table.Td>
                        </Table.Tr>
                        <Table.Tr>
                          <Table.Td>新パスワード</Table.Td>
                          <Table.Td>
                            <PasswordInput
                              name="new_password"
                              value={newPass}
                              onChange={(event) =>
                                setNewPass(event.currentTarget.value)
                              }
                            />
                          </Table.Td>
                        </Table.Tr>
                      </Table.Tbody>
                    </Table>
                    <Alert
                      variant="light"
                      title="注意点"
                      icon={<IconInfoCircle />}
                      my={10}
                    >
                      パスワードは8文字以上、１５文字以内で設定してください。
                      <br />
                      パスワードには以下の文字が使用できます。
                      <br />
                      • 英字 A～Z、a～z
                      <br />
                      • 数字 0～9
                      <br />
                      • 記号 . - _<br />
                      ※メールアドレス同じ文字列はセキュリティ上使用できません。
                      安易に推測されないパスワードを設定してください。
                      <br />
                    </Alert>
                    <Center>
                      <Button type="submit">確認</Button>
                    </Center>
                  </form>
                </Card>
              </Grid.Col>
              <Grid.Col span={{ base: 12, md: 6 }}>
                <Card
                  padding="sm"
                  withBorder
                  style={{
                    minWidth: "25vw",
                  }}
                >
                  <Card.Section
                    style={{
                      textAlign: "center",
                      background: "rgb(201,42,42)",
                      color: "white",
                      fontWeight: 700,
                      padding: 5,
                    }}
                  >
                    支払い情報
                  </Card.Section>
                  <Table>
                    <Table.Tbody>
                      <Table.Tr>
                        <Table.Td>現在のお支払い方法 </Table.Td>
                        <Table.Td>{userData.cPaymentKbn}</Table.Td>
                      </Table.Tr>
                      <Table.Tr>
                        <Table.Td colSpan={2} style={{ textAlign: "center" }}>
                          お客様番号「{userData.user.amix_account}
                          」のご契約に合算
                        </Table.Td>
                      </Table.Tr>
                    </Table.Tbody>
                  </Table>
                </Card>
                <Space h="md" />
                <Card
                  padding="sm"
                  withBorder
                  style={{
                    minWidth: "25vw",
                  }}
                >
                  <Card.Section
                    style={{
                      textAlign: "center",
                      background: "rgb(201,42,42)",
                      color: "white",
                      fontWeight: 700,
                      padding: 5,
                    }}
                  >
                    ami:tool利用解約
                  </Card.Section>
                  <Text style={{ textAlign: "center" }} p={10}>
                    下記「解約する」ボタンより退会処理を行ってください。
                  </Text>
                  <Center>
                    <Button
                      onClick={() => {
                        navigate("/account/kaiyaku");
                        // open();
                        // setModalType("delete");
                      }}
                    >
                      解約する
                    </Button>
                  </Center>
                </Card>
              </Grid.Col>
            </Grid>
          </Center>
        </>
      )}
    </>
  );
}
