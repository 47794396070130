import { Container, Group } from "@mantine/core";
import classes from "./FooterMenu.module.css";
import { Link } from "react-router-dom";

const links = [
  {
    link: "https://cp.amixcom.jp/webmail/",
    label: "ウェブメール",
  },
  // {
  //   link: "https://secure.enat.jp/web-ftp/list/index.cgi?mode=init&d=/",
  //   label: "ウェブFTP",
  // },
];

const footerIcon = process.env.PUBLIC_URL + "/footer_logo.png";

export default function FooterMenu() {
  const items = links.map((link) => (
    <Link
      to={link.link}
      key={link.label}
      className={classes.link}
      target="_blank"
    >
      {link.label}
    </Link>
  ));

  return (
    <div className={classes.footer}>
      <Container className={classes.inner}>
        <Link to={"https://amixcom.jp/"} target="_blank">
          <img src={footerIcon} alt="amix com" />
        </Link>
        <Group className={classes.links}>{items}</Group>
      </Container>
    </div>
  );
}
